<template>
  <div class="v-concepts" :class="parentSlugPath">
    <TheHeading class="v-concepts__title" level="h1">{{
      conceptsTitle
    }}</TheHeading>
    <Breadcrumbs />
    <div class="v-concepts__filtering">
      <div class="v-concepts__search">
        <Searchbox
          class="c-concepts-search__searchbox u-px--3"
          :class="{ 'c-concepts-search__searchbox--has-phrase': term }"
          :searchFormLabel="$t('SEARCH_FORM')"
          :searchPlaceholder="$t('CONCEPTS_SEARCH_PLACEHOLDER')"
          :searchButton="$t('SEARCH')"
          :characters="2"
          @submit-search="setPhrase"
        />
      </div>

      <div class="v-concepts__filter">
        <LanguageSelect
          v-if="languageList.length"
          :languageList="languageList"
          :currentLanguage="currentLanguage"
          :canClear="true"
          :canDeselect="true"
          :placeholder="$t('CONCEPTS_LANG_PLACEHOLDER')"
          :label="$t('CONCEPTS_LANG_LABEL')"
          @language-change="onLanguageChange"
        />
      </div>
    </div>

    <div class="v-concepts__wrapper">
      <Card
        v-for="concept in finalConceptsResults"
        :key="concept.id"
        :title="concept.name"
        :description="concept.description"
        path="#"
        :button-text="$t('CONCEPTS_CARD_BUTTON_TEXT')"
        type="concept"
        class="v-archive-fag__card"
        :action="() => onShowConcept(concept)"
      />
    </div>
    <div v-if="!loading && !finalConceptsResults.length">
      <TheHeading class="v-concepts__no-results">
        {{ $t('CONCEPTS_SEARCH_NO_RESULTS') }}
      </TheHeading>
    </div>
    <div v-if="loading">
      <TheHeading class="v-concepts__loading">
        {{ $t('CONCEPTS_SEARCH_LOADING') }}...
      </TheHeading>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, defineComponent, watch } from 'vue'
import {
  useBreadcrumbs,
  useStructures,
  useTenant,
  useLocalizations,
} from '@/core'

import {
  getConceptsLanguage,
  setConceptsLanguage,
} from '@/services/languageSelect'
import { useI18n } from 'vue-i18n'
import useTranslations from '@/composables/useTranslations'
import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import Card from '@/components/Card.vue'
import Searchbox from '@forlagshuset/v-searchbox'

const lunr = require('lunr')
require('lunr-languages/lunr.stemmer.support')(lunr)
require('lunr-languages/lunr.no')(lunr)
lunr.no.wordCharacters = lunr.no.wordCharacters + '\0-9';
lunr.no.trimmer = lunr.trimmerSupport.generateTrimmer(lunr.no.wordCharacters);

export default defineComponent({
  name: 'ConceptsSearch',

  components: {
    TheHeading,
    Breadcrumbs,
    LanguageSelect,
    Card,
    Searchbox,
  },

  setup() {
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
    const currentLanguage = ref('')
    const loading = ref(true)
    const lunrIdx = ref(null)
    const term = ref('')
    const { tenant } = useTenant()
    const { setModal } = useModal()
    const parentSlugPath = computed(() => tenant.value.concepts.slug)
    const {
      getStructuresBySlugPath,
      fetchStructuresChildren,
      getStructuresChildrens,
    } = useStructures()
    const { gqlStructureQuery, gqlStructureChildrenQuery, isFallbackLanguage } =
      useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()
    const { locale } = useI18n()
    const parentStructure = computed(() =>
      getStructuresBySlugPath(
        parentSlugPath.value,
        true,
        'DIRECTORY',
        locale.value,
      ),
    )
    const languageList = computed(() => {
      return tenant.value.concepts.langs.map((lang) => {
        return {
          value: lang.code,
          label: lang.label[locale.value],
        }
      })
    })
    const onLanguageChange = async (lang) => {
      await setConceptsLanguage(lang)
      currentLanguage.value = lang
    }
    const conceptsTitle = computed(
      () => (parentStructure.value && parentStructure.value.name) || '',
    )
    const concepts = computed(() => {
      return getStructuresChildrens(
        parentStructure.value.id,
        'DIRECTORY',
        locale.value,
      )
    })
    const finalConceptsResults = computed(() => {
      let result
      const conceptsData = concepts.value
      if (!lunrIdx.value) return []
      if (!term.value)
        return conceptsData.sort((a, b) => a.name.localeCompare(b.name, 'no'))
      result = lunrIdx.value
        .search(term.value + '*')
        .map(
          (obj) => conceptsData.filter((concept) => obj.ref === concept.id)[0],
        )

      return result
    })
    const onShowConcept = (concept) => {
      setModal('ModalConcept', {
        title: concept.name,
        content: concept,
        type: 'concept',
        options: {
          translation: currentLanguage.value,
        },
      })
    }
    const setPhrase = (e) => {
      term.value = e
    }

    const loadContent = async () => {
      await gqlStructureQuery(tenant.value.concepts.slug)
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(
            parentStructure.value.id,
            locale.value,
          )
        }
      } catch {
        //
      }
      try {
        if (!isFallbackLanguage.value) {
          const limit = 200
          let hasItems = true
          let page = 0
          while (hasItems) {
            const res = await gqlStructureChildrenQuery(
              parentStructure.value.id,
              limit,
              page,
            )
            const { total } = res.data.structure.data.children.pagination
            page++
            if (total <= page * limit) {
              hasItems = false
            }
          }
        } else {
          await fetchStructuresChildren(
            tenant.value.concepts.slug,
            {
              limit: 1000,
              filter: `{"type": "DIRECTORY"}`,
            },
            {
              namespace: tenant.value.concepts.namespace,
            },
          )
        }
      } catch {
        //
      }

      currentLanguage.value = (await getConceptsLanguage()) || ''

      lunrIdx.value = lunr(function () {
        this.use(lunr.no)
        this.pipeline.remove(lunr.stemmer)
        this.searchPipeline.remove(lunr.stemmer)
        this.pipeline.remove(lunr.stopWordFilter)
        this.searchPipeline.remove(lunr.stopWordFilter)
        this.pipeline.remove(lunr.no.stemmer)
        this.searchPipeline.remove(lunr.no.stemmer)
        this.pipeline.remove(lunr.no.stopWordFilter)
        this.searchPipeline.remove(lunr.no.stopWordFilter)
        this.ref('id')
        this.field('name')

        concepts.value.forEach((st) => {
          this.add(st)
        })
      })
      loading.value = false
    }

    watch(locale, () => {
      loadContent()
    })

    onMounted(async () => {
      clearBreadcrumbs()
      loadContent()
      setBreadcrumbs([{ name: conceptsTitle.value }])
    })

    return {
      loading,
      conceptsTitle,
      parentSlugPath,
      finalConceptsResults,
      onShowConcept,
      setPhrase,
      term,
      languageList,
      onLanguageChange,
      currentLanguage,
    }
  },
})
</script>

<style lang="scss">
.v-concepts {
  max-width: rem(860px);

  @include bp(xxlarge) {
    max-width: rem(1200px);
  }

  .c-searchbox__button,
  .c-searchbox__input {
    line-height: 2rem;
  }

  .c-searchbox {
    position: relative;
    padding: 0;
  }

  .v-concepts__filtering {
    @include bp(medium) {
      display: flex;
      justify-content: space-between;
    }
  }

  .c-searchbox__input {
    border: 2px solid $searchbox__border;
    border-radius: 1rem;
    max-width: 100%;
    width: 25rem;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    box-sizing: border-box;

    &::placeholder {
      color: $color-text--4;
      font-weight: 700;
      font-size: rem(14px);
    }
  }

  .c-searchbox__cross-button {
    right: 2.5rem;
  }

  .c-searchbox__button {
    font-size: 0 !important;
    margin-left: 0.5rem !important;
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &::before {
      content: '\e905';
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0051 10.0784L10.9685 10.0417H10.9167H10.3088L10.1958 9.93276C10.9856 8.97372 11.4583 7.74722 11.4583 6.41667C11.4583 3.35596 8.97737 0.875 5.91667 0.875C2.85596 0.875 0.375 3.35596 0.375 6.41667C0.375 9.47737 2.85596 11.9583 5.91667 11.9583C7.24722 11.9583 8.47372 11.4856 9.43276 10.6958L9.54167 10.8088V11.4167V11.4685L9.57837 11.5051L13.745 15.6635L13.8334 15.7517L13.9217 15.6634L15.1634 14.4217L15.2517 14.3334L15.1635 14.245L11.0051 10.0784ZM5.91667 10.0417C3.9107 10.0417 2.29167 8.42263 2.29167 6.41667C2.29167 4.4107 3.9107 2.79167 5.91667 2.79167C7.92263 2.79167 9.54167 4.4107 9.54167 6.41667C9.54167 8.42263 7.92263 10.0417 5.91667 10.0417Z' fill='%2390A0B7' stroke='%2390A0B7' stroke-width='0.25'/%3E%3C/svg%3E");
      width: 1rem;
      height: 1rem;
      margin-top: 0.125rem;

      .c-concepts-search__searchbox--has-phrase & {
        display: none;
      }
    }
  }

  .c-searchbox__cross-button {
    display: none;

    .c-concepts-search__searchbox--has-phrase & {
      display: block;
    }
  }
}

.v-concepts__wrapper {
  margin-top: rem(35px);
  .c-card--concept {
    margin-bottom: 1rem;
  }

  @include bp(medium) {
    gap: 1rem;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    .c-card--concept {
      margin-bottom: 1.25rem;
    }
  }

  @include bp(xxlarge) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
