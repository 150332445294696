import DB from '@/core/services/db'
import { useAuth } from '@/core'

const db = new DB('edtech')
const { instance } = useAuth()
const conceptsLanguageId = `${process.env.VUE_APP_COSS_NAMESPACE}-${instance().getUser()._id}-concepts-language`

const getConceptsLanguage = async () => {
  try {
    const lang = await db.getData(conceptsLanguageId)

    return lang.value
  } catch {
    return ''
  }
}

const setConceptsLanguage = async (lang) => {
  try {
    await db.createOrUpdate({
      _id: conceptsLanguageId,
      name: 'conceptsLanguage',
      value: lang,
    })
  } catch {
    // silence
  }
}

export { getConceptsLanguage, setConceptsLanguage }
